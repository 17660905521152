import {ParsedUrlQuery} from "querystring";
import {getRevalidationTime} from "~/tenant/selectors";
import React from "react";
import {GetStaticProps, GetStaticPaths} from "next";
import {useRouter, NextRouter} from "next/router";
import {Global, css} from "@emotion/core";
import Head from "next/head";
import {CURRENCIES, DEFAULT_LOCALE, LOCALES} from "@pency/constants/catalogs/i18n";

import api from "~/tenant/api";
import ProductsScreen from "~/product/screens/Products";
import {ClientTenant} from "~/tenant/types";
import {Product} from "~/product/types";
import {Provider as CartProvider} from "~/cart/context";
import {Provider as AnalyticsProvider} from "~/analytics/context";
import {Provider as ProductProvider} from "~/product/context";
import {Provider as TenantProvider} from "~/tenant/context";
import {META} from "~/app/constants/config";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {parseSlug} from "~/utils/url";
import { formatImageUrl } from "~/product/utils/formatImage";
//import { ErrorBoundary } from "~/reporting/bugsnag";

interface Props {
  tenant: ClientTenant;
  products: Product[];
  lastUpdate: number;
  nextUpdate: number;
}

interface Params extends ParsedUrlQuery {
  slug: ClientTenant["slug"];
}

interface Router extends NextRouter {
  query: {
    slug?: string[];
  };
}

const SlugRoute: React.FC<Props> = ({tenant, products}) => {
  // Get router instance
  const {query} = useRouter() as Router;
  // Get the real product from the product id url
  const productId = parseSlug(query.slug ?? []);
  const product = productId
    ? products.find((product) => product.id === productId) || null
    : null;

    React.useEffect(() => {
     window.dataLayer.push({'event': 'optimize.activate'});
    },[])

  const banner = tenant?.banner ? formatImageUrl(tenant.banner, 'og') : undefined
  const logo = formatImageUrl(tenant.logo ?? '', 'og')

  const productImage = formatImageUrl(product?.images?.[0] ?? '', 'og')
    
  return (
    <>
      <Global
        styles={css`
          /* latin */
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          /* latin */
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }

          html,
          body {
            height: 100%;
            width: 100%;
            max-width: 100vw;
          }

          * {
            letter-spacing: -0.2px;
            touch-action: manipulation;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
              "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
          }
        `}
      />
      <Head>
      <style>{`.async-hide { opacity: 0 !important}`} </style>
        <script dangerouslySetInnerHTML={{__html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
          h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
          (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
          })(window,document.documentElement,'async-hide','dataLayer',4000,
          {'OPT-K9K2LPX':true})`}}></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-93277234-2"
        ></script>
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NPX4DNK"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-3MDHSR1S7R"></script>
        <script
          dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-177635812-2', { 'optimize_id': 'OPT-K9K2LPX'});
          gtag('config', 'G-VZ4QZT0QRY');
          `,
          }}/>
        <title>{tenant.title || META.title} (@{tenant.slug}) / Pency</title>
        <meta content={tenant.description || META.description} name="description" />
        {tenant.banner && <link rel="preload" href={banner} as="image" />}
        {tenant.logo && <link rel="preload" href={logo} as="image" />}
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <script type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "url": "${META.url}/${tenant.slug}",
              "name": ${JSON.stringify(tenant.title)},
              "alternateName": "@${tenant.slug}",
              "image": "${tenant.logo}",
              "description": ${JSON.stringify(tenant.description)},
              ${tenant.location ? (`
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "${tenant.location.coordinates.lat}",
                "longitude": "${tenant.location.coordinates.lng}"
              },
              "address": "${tenant.location.address}",
              `) : ''}
              "telephone": "${tenant.phone}",
              "currenciesAccepted": "${CURRENCIES[tenant.country as keyof typeof CURRENCIES]}",
              "paymentAccepted": "${tenant.mercadopago ? "Debit Card, Credit Card, Cash" : "Cash" }",
              "priceRange": "$$$"
            }`,
          }}
        />
        {product && <script type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org",
              "@type": "Product",
              "name": ${JSON.stringify(product.title)},
              "image": [${product.images.map((i => `"${i}"`))}],
              "description": ${JSON.stringify(product.description)},
              "category": "${product.category}",
              "offers": {
                "@type": "Offer",
                "priceCurrency": "${CURRENCIES[tenant.country as keyof typeof CURRENCIES]}",
                "price": "${product.price.toFixed(2)}",
                "availability": "https://schema.org/${product.type === 'available' ? "InStock" : "OutOfStock"}"
              },
              "brand": {
                "@type": "Brand",
                "name": ${JSON.stringify(tenant.title)}
              }
            }`,
          }}
        />}
        <link href={META.favicon} rel="icon" />
        <link href={tenant.logo || META.appleicon} rel="apple-touch-icon" />
        <meta content={tenant.color || META.theme} name="theme-color" />
        <meta content={tenant.keywords || META.keywords} name="keywords" />
        <meta content={META.author} name="author" />
        <meta content={META.author} property="og:site_name" />
        <meta content={META.fbapp} property="fb:app_id" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta
          content={tenant.twitter ? `@${tenant.twitter}` : META.twitter}
          name="twitter:creator"
        />
        <meta content={META.twitter} name="twitter:site" />
        {(tenant.tier === "PLAN_PREMIUM" || "PLAN_TRIAL") && tenant.ga && (
          <>
            <script defer src={`https://www.googletagmanager.com/gtag/js?id=${tenant.ga}`} />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-177635812-1');

                gtag('config', '${tenant.ga}');`,
              }}
            />
          </>
        )}
        {(tenant.tier === "PLAN_PREMIUM" || "PLAN_COMMERCIAL" || "PLAN_TRIAL") && tenant.pixel && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${tenant.pixel}');
                  fbq('track', 'PageView');`,
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${tenant.pixel}&ev=PageView&noscript=1" />`,
              }}
            />
          </>
        )}
        {product ? (
          <>
            <link href={`${META.url}/${tenant.slug}/${product.slug}`} rel="canonical" />
            <meta content={product.title} name="twitter:title" />
            <meta content={product.description} name="twitter:description" />
            <meta content={productImage || META.banner?.url} property="twitter:image" />
            <meta content={`${META.url}/${tenant.slug}/${product.slug}`} property="og:url" />
            <meta content="article" property="og:type" />
            <meta content={product.title} property="og:title" />
            <meta content={product.description} property="og:description" />
            <meta content={productImage} property="og:image" />
            <meta content={productImage} property="og:image:secure" />
            <meta content={productImage} property="og:image:url" />
            <meta content="image/jpeg" property="og:image:type" />
            <meta content={product.title} property="og:image:alt" />
            <meta content={product.price.toFixed(2)} property="og:price:amount" />
            <meta content={CURRENCIES[tenant.country as keyof typeof CURRENCIES]} property="og:price:currency" />
            <meta content={product.price.toFixed(2)} property="product:price:amount" />
            <meta content={CURRENCIES[tenant.country as keyof typeof CURRENCIES]} property="product:price:currency" />
            <meta content={product.category} property="article:section" />
            <meta content={product.title} itemProp="name" />
            <meta content={product.description} itemProp="description" />
            <meta content={productImage} itemProp="image" />
          </>
        ) : (
          <>
            <link href={`${META.url}/${tenant.slug}`} rel="canonical" />
            <meta content={tenant.title || META.title} name="twitter:title" />
            <meta content={tenant.description || META.description} name="twitter:description" />
            <meta
              content={banner || logo || META.banner?.url}
              property="twitter:image"
            />
            <meta content={`${META.url}/${tenant.slug}`} property="og:url" />
            <meta content="website" property="og:type" />
            <meta
              content={tenant.title ? `Pency | ${tenant.title}` : META.title}
              property="og:title"
            />
            <meta content={tenant.description || META.description} property="og:description" />
            <meta content={banner || logo || META.banner?.url} property="og:image" />
            <meta
              content={banner || logo || META.banner?.url}
              property="og:image:secure"
            />
            <meta
              content={banner|| logo || META.banner?.url}
              property="og:image:url"
            />
            <meta content={META.banner?.format} property="og:image:type" />
            <meta content={META.banner?.width} property="og:image:width" />
            <meta content={META.banner?.height} property="og:image:height" />
            <meta content={tenant.title || META.title} property="og:image:alt" />
          </>
        )}
        <meta
          content="width=device-width, initial-scale=1.0, viewport-fit=contain"
          name="viewport"
        />
        {tenant?.meta?.map((meta, index) => (
          <meta key={meta.property || index} {...meta} />
        ))}
      </Head>
      <TenantProvider initialValue={tenant}>
        <ProductProvider initialValues={products}>
          <AnalyticsProvider>
            <CartProvider>
              <ProductsScreen />
            </CartProvider>
          </AnalyticsProvider>
        </ProductProvider>
      </TenantProvider>
    </>
  );
};

// export const getServerSideProps = async ({ params }) => {
//   try {
//     const [slug] = params.slug

//     // Get the tenant for this page slug
//     const {products, ...tenant}: ClientTenant = await api.fetch({slug});

//     if (tenant.tier === 'PLAN_FREE') return {
//       redirect: {
//         destination: `/free/${slug}`,
//       },
//     }

//     const storeLocale = LOCALES[tenant.country];
//     const availableLocales = Object.values(LOCALES);
//     const currentLocale = availableLocales.includes(storeLocale) ? storeLocale : DEFAULT_LOCALE;

//     // Return the props and revalidation times
//     return {
//       props: {
//         ...await serverSideTranslations(currentLocale),
//         tenant, products
//       },
//     };
//   } catch (err) {
//     if (err.displayMaintenancePage) {
//       return {
//         redirect: {
//           permanent: false,
//           destination: 'https://merry-shortbread-c46a23.netlify.app/maintenance',
//         },
//         props:{},
//       };
//     }
//     return {
//       // If something failed return a status code that will be intercepted by _app
//       props: {
//         ...await serverSideTranslations(DEFAULT_LOCALE),
//         statusText: err?.statusText || '',
//         statusCode: err?.status || err?.statusCode || 404,
//       },
//     };
//   }
// };

export const getStaticProps: GetStaticProps<any, Params> = async ({ params }) => {
  try {
    const [slug] = params?.slug ?? [];

    // Get the tenant for this page slug
    const {products, ...tenant}: ClientTenant = await api.fetch({slug});

   // Get the revalidation time
   const revalidationTime = getRevalidationTime(tenant.tier);

   // Get the last updated time
   const lastUpdate = +new Date();

   // Get the next updated time
   const nextUpdate = lastUpdate + revalidationTime * 1000;

   const storeLocale = LOCALES[tenant.country as keyof typeof LOCALES];
   const availableLocales = Object.values(LOCALES);
   const currentLocale = availableLocales.includes(storeLocale) ? storeLocale : DEFAULT_LOCALE;

   return {
     props: {
       ...await serverSideTranslations(currentLocale),
      tenant, products, lastUpdate, nextUpdate,
     },
   };
 }
   catch (err) {
     if (err.displayMaintenancePage) {
       return {
         redirect: {
           permanent: false,
           destination: 'https://merry-shortbread-c46a23.netlify.app/maintenance',
         },
         props:{},
       };
     }
     return {
       // If something failed return a status code that will be intercepted by _app
       props: {
         ...await serverSideTranslations(DEFAULT_LOCALE),
         statusText: err?.statusText || '',
         statusCode: err?.status || err?.statusCode || 404,
       },
      // Revalidate after an hour
      revalidate: 3600,
     };
   }}

   export const getStaticPaths: GetStaticPaths = async () => {
     return {
       // Map slugs as params
       paths: [],
       // Build not relevant ones on demand
       fallback: "blocking",
     };
   };

export default SlugRoute;
